
#logo{
    height: 50%;
    width: auto;
    margin: 0.5em 0;
}

#min-logo{
    height: 50%;
    width: auto;
    margin: 0.5em 0;
}

.sidebar .sidebar-brand {
    padding: 0 1rem !important;
}