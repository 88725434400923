footer.sticky-footer {
	padding: 2rem 0;
	flex-shrink: 0;
}

footer.sticky-footer .copyright {
	line-height: 1;
	font-size: 0.8rem;
}

body.sidebar-toggled footer.sticky-footer {
	width: 100%;
}

.bg-white {
	background-color: #fff !important;
}

.container {
	min-width: 992px !important;
}

.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}


.container {
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

.navbar .container,
.navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.text-center {
  text-align: center !important;
}