.btn-start{
    margin: 3rem 0;
}

@media only screen and (min-width: 768px) {
    .btn-start{
        margin: 0;
    }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .btn-start{
        margin: 0;
    }
}
